/* eslint-disable no-unused-expressions */
import React, { FC, useEffect } from 'react';
import { PageLayout, PageHeading } from 'components';
import { graphql } from 'gatsby';

const DietitianBookings: FC = ({ data }: any) => {
  const {
    DietitianBookings: {
      title,
      seo: { metaDescription, metaTitle, shareImage },
    },
    serviceBanner,
  } = data;
  const { banner } = serviceBanner;
  useEffect(() => {
    window.addEventListener('message', function handleIFrameMessage(e) {
      const clinikoBookings: any = document.getElementById('cliniko-44744185');
      if (typeof e.data !== 'string') return;
      if (e.data.search('cliniko-bookings-resize') > -1) {
        const height = Number(e.data.split(':')[1]);
        clinikoBookings.style.height = `${height}px`;
      }
      e.data.search('cliniko-bookings-page') > -1 &&
        clinikoBookings.scrollIntoView();
    });
  }, []);
  return (
    <PageLayout
      title={title}
      metaTitle={metaTitle ?? title}
      description={metaDescription}
      image={shareImage?.localFile?.publicURL}
      hasContainerClass
      bannerTitle={banner?.title}
      bannerTagline={banner?.tagline}
      bannerImage={banner?.image}
      bannerButton={banner?.ctaButton}
      bannerPlaceholder={banner?.postcodePlaceholder}
      dealTitle="Nutrition Page"
    >
      <PageHeading title={title} level={1} />
      <div className="dietition-booking-wrapper">
        <iframe
          id="cliniko-44744185"
          title={title}
          src="https://get-going.au3.cliniko.com/bookings?business_id=700133283232488984&embedded=true"
          frameBorder="0"
          scrolling="auto"
          width="100%"
          height="1000"
          style={{ pointerEvents: 'auto' }}
        />
      </div>
    </PageLayout>
  );
};

export default DietitianBookings;
export const query = graphql`
  query DietitianBookings {
    DietitianBookings: strapiDietitianBookingsPage {
      slug
      title
      seo {
        metaDescription
        metaTitle
        shareImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 400, height: 400)
            }
          }
        }
      }
    }
    serviceBanner: strapiServicePage(slug: { eq: "nutrition" }) {
      banner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
